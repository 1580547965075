<template>
  <div class="racers">
    <div id="dataTable">
      <v-container>
        <v-row
          ><v-col>
            <v-card>
              <v-card-title> 2022 Haensli Cup Registration </v-card-title>
              <v-card-text>
                <div class="text-body-1">
                  <p>
                    The Race Entry Fee is payable on Race day when you collect
                    your bib - cash, and internet banking are accepted (no
                    credit cards or EFTPOS sorry).
                  </p>
                </div>
                <div class="text-body-1">
                  Collect your bibs from Lorenz's Bar and Cafe from 8.30am on
                  race day.
                </div>
                <div class="text-h6">
                  <p>
                    PLEASE NOTE: Entrants must be wearing a helmet or they will
                    not be allowed to race.
                  </p>
                </div>
                <div class="text-body-1">
                  <p>
                    All are to follow instructions from the starter and ski club
                    course controller, clear the finish area quickly and return
                    their bibs.
                  </p>
                  <p>The race event is smoke, drug and alcohol free.</p>
                  Prize giving is at 4pm at The Ruapehu Ski Club War Memorial
                  Lodge on Hut Flat. Please Read the
                  <a
                    href="https://drive.google.com/file/d/14oujfRj3KmcvJiL-A-WOSeK6d91QiZL9/view"
                    target="_new"
                    >Snow Code</a
                  >
                </div>
              </v-card-text>
            </v-card></v-col
          ></v-row
        ><v-row
          ><v-col>
            <v-card
              v-if="!finished"
              :loading="this.finished"
              :disabled="this.finished"
              ><v-form ref="newRacerForm">
                <v-card-title>
                  <span class="text-h5">New Racer</span>
                </v-card-title>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="6" md="8">
                        <v-text-field
                          label="Full Name"
                          required
                          v-model="newRacer.name"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="6" md="4">
                        <v-select
                          :items="genders"
                          label="Gender"
                          v-model="newRacer.gender"
                          :rules="[rules.required]"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Email*"
                          required
                          v-model="newRacer.email"
                          :rules="[rules.required, rules.email]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          label="Phone*"
                          required
                          dense
                          v-model="newRacer.phone"
                          :rules="[rules.required]"
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="newRacer.club"
                          :items="clubs"
                          item-text="name"
                          item-value="id"
                          label="Club"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                      <v-col cols="12" sm="6">
                        <v-autocomplete
                          v-model="newRacer.division"
                          :items="divisions"
                          item-text="name"
                          item-value="id"
                          label="Division"
                          :rules="[rules.required]"
                        ></v-autocomplete>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" sm="6">
                        <v-menu
                          ref="editDOBMenu"
                          v-model="editDOBMenu"
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="newRacer.dob"
                              label="DOB"
                              prepend-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                              :hint="dobHint"
                              persistent-hint
                              :rules="[rules.required]"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="newRacer.dob"
                            :active-picker.sync="activeEditDOBPicker"
                            :max="
                              new Date(
                                Date.now() -
                                  new Date().getTimezoneOffset() * 60000
                              )
                                .toISOString()
                                .substr(0, 10)
                            "
                            min="1930-01-01"
                            @change="saveEditDOB"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="3" sm="3">
                        <v-radio-group
                          v-model="newRacer.professional"
                          row
                          mandatory
                        >
                          <v-radio label="Amateur" value="false" />
                          <v-radio label="Professional" value="true" />
                        </v-radio-group>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-checkbox
                          label="By entering this event I acknowledge and agree to the following: The event may be hazardous, and may result in injury or death. I participate in this event entirely at my own risk. I suffer no medical conditions or disabilities that may render me unsuitable for this event. I release the organisers and any of their employees, volunteers or agents of this event from any liability of damage, loss, or costs that I might suffer."
                          v-model="newRacer.terms"
                          :rules="[rules.required]"
                        >
                        </v-checkbox> </v-col></v-row
                    ><v-row>
                      <v-col>
                        <v-checkbox
                          label="I agree to follow the instructions from the starter and RSC ski club course controller and obey the RAL snow code."
                          v-model="newRacer.agree"
                          :rules="[rules.required]"
                        >
                        </v-checkbox>
                      </v-col>
                    </v-row>
                  </v-container>
                  <small>*indicates required field</small>
                </v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>

                  <v-btn
                    color="blue darken-1"
                    x-large
                    @click="submitNewRacer()"
                  >
                    Submit Registration
                  </v-btn>
                </v-card-actions></v-form
              >
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <v-snackbar v-model="errorPopup" timeout="2000" color="red darken-4">
      {{ errorMsg }}

      <template v-slot:action="{ attrs }">
        <v-btn color="red" text v-bind="attrs" @click="errorPopup = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="successPopup" max-width="600" color="green">
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"
          >Entry Recieved!</v-card-title
        >
        <v-card-text>
          <div class="text-h6">
            Thank you for submitting your Haensli Cup registration. You will
            recieve an email shortly to confirm your registration.
          </div>

          <div class="text-body-2">
            If you are submitting entries on behalf of your club, you can add
            another entrant now.
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="green"
            text
            v-bind="attrs"
            @click="successPopup = false"
          >
            I'm Done!
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="green" text v-bind="attrs" @click="resetFormSameClub()">
            Add another racer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <ConfirmDlg ref="confirm" />
  </div>
</template>

<script>
import axios from "axios";
export default {
  components: {
    ConfirmDlg: () => import("../components/ConfirmDlg"),
  },
  data() {
    return {
      loading: true,
      mobile: false,
      activePicker: null,
      activeEditDOBPicker: null,
      menu: false,
      editDOBMenu: false,
      dialog: false,
      editRacerTeams: [],
      newRacer: {
        id: 0,
        name: "",
        email: "",
        gender: "",
        phone: "",
        club: "",
        bib: null,
        division: "",
        dnf: false,
        dsq: false,
        notes: "",
        team: "0",
        time: "",
        updated: "",
        dob: "",
        professional: null,
      },
      selectedRows: [],
      genders: ["M", "F"],
      newRacerClub: "",
      newRacerDivision: "",
      newRacerGender: "",
      newRacerPhone: "",
      newRacerEmail: "",
      newRacerNotes: "",
      divLabel: "Division",
      newRacerName: "",
      newRacerDOB: "",
      dobHint: "",
      finished: false,
      showEdit: false,
      showDelete: false,
      newRacerProfessional: "false",
      divisions: [],
      racers: [],
      clubs: [],
      teams: [{ name: "Noned", id: 0 }],
      errorMsg: "",
      errorPopup: false,
      successMsg: "",
      successPopup: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",
        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      columns: [
        {
          label: "ID",
          field: "id",
          type: "number",
          hidden: true,
        },
        {
          label: "Bib",
          field: "bib",
          type: "number",
        },
        {
          label: "Name",
          field: "name",
        },
        {
          label: "DOB",
          field: "dob",
          hidden: true,
        },
        {
          label: "M/F",
          field: "gender",
        },
        {
          label: this.divLabel,
          field: "division",
          filterOptions: {
            enabled: false,
            //filterDropdownItems: ["Open", "Junior", "Masters", "Snowboard"],
            //TODO: this needs to be populated from the this.divisions.
          },
        },
        {
          label: "Club",
          field: "club",
        },
        {
          label: "Notes",
          field: "notes",
          hidden: this.$vuetify.breakpoint.xs,
        },
      ],
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.newRacerDOB);
    },
    computedDateFormattedEdit() {
      return this.formatDate(this.newRacer.dob);
    },
    mobilecomputed() {
      return this.$vuetify.breakpoint.xs;
    },
  },
  watch: {
    menu(val) {
      //resets the DOB picker in the new racer form back to Year.
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    editDOBMenu(val) {
      //Resets the DOB picker in the edit racer form.
      val && setTimeout(() => (this.activeEditDOBPicker = "YEAR"));
    },
    newRacerDOB(val) {
      if (val) {
        this.dateFormatted = this.formatDate(this.newRacerDOB);
      }
      this.dateFormatted = this.formatDate(this.newRacerDOB);
    },
  },
  methods: {
    onRowDblClick(params) {
      // params.row - row object
      // params.pageIndex - index of this row on the current page.
      // params.selected - if selection is enabled this argument
      // indicates selected or not
      // params.event - click event
      //this.itemsWithClub();

      this.newRacer.name = params.row.name;
      this.newRacer.email = params.row.email;
      this.newRacer.gender = params.row.gender;
      this.newRacer.phone = params.row.phone;
      this.newRacer.id = params.row.id;
      this.newRacer.club = this.clubs.find((x) => x.name == params.row.club).id;
      this.newRacer.bib = params.row.bib;
      this.newRacer.division = this.divisions.find(
        (x) => x.name == params.row.division
      ).id;
      this.newRacer.professional = params.row.professional;
      this.newRacer.dnf = params.row.dnf;
      this.newRacer.dsq = params.row.dsq;
      this.newRacer.notes = params.row.notes;
      this.newRacer.team = params.row.team;
      this.newRacer.time = params.row.time;
      this.newRacer.updated = params.row.updated;
      this.newRacer.dob = params.row.dob;
      console.log("Double Clicked");
    },

    getDivLabel() {
      if (this.$vuetify.breakpoint.xs) {
        this.divLabel = "Div";
      } else {
        this.divLabel = "Division";
      }
    },
    async submitNewRacer() {
      if (this.$refs.newRacerForm.validate()) {
        try {
          const newRacer = {
            name: this.newRacer.name,
            dob: this.newRacer.dob,
            gender: this.newRacer.gender,
            professional: this.newRacer.professional,
            email: this.newRacer.email,
            phone: this.newRacer.phone,
            club: this.newRacer.club,
            division: this.newRacer.division,
            notes: this.newRacer.notes,
            agree: this.newRacer.agree,
            terms: this.newRacer.terms,
          };

          const response = await axios.post(
            "https://dev.app1.rsc.org.nz/racers",
            newRacer
          );
          // JSON responses are automatically parsed.
          console.log(response);
          //this.racers = response.data;
        } catch (error) {
          console.log(error);
          this.errorMsg = error;
          this.errorPopup = true;
        } finally {
          this.loading = false;

          if (!this.errorPopup) {
            this.finished = true;
            this.successMsg = "Success! New Racer Added!";
            this.successPopup = true;
           
          }
        }
      }
    },
    async submitEditRacer() {
      if (this.$refs.editRacerForm.validate()) {
        try {
          const editRacer = {
            name: this.editRacer.name,
            dob: this.editRacer.dob,
            gender: this.editRacer.gender,
            professional: this.editRacer.professional,
            email: this.editRacer.email,
            phone: this.editRacer.phone,
            club: this.editRacer.club,
            division: this.editRacer.division,
            notes: this.editRacer.notes,
            id: this.editRacer.id,
            dnf: this.editRacer.dnf,
            dsq: this.editRacer.dsq,
            time: this.editRacer.time,
            bib: this.editRacer.bib,
            team: this.editRacer.team,
          };
          if (editRacer.bib == "None") {
            editRacer.bib = null;
          }
          if (editRacer.team == "None") {
            editRacer.team = null;
          }
          const response = await axios.put(
            "https://dev.app1.rsc.org.nz/racer",
            editRacer
          );
          // JSON responses are automatically parsed.
          console.log(response);
          //this.racers = response.data;
        } catch (error) {
          console.log(error);
          this.errorMsg = error;
          this.errorPopup = true;
        } finally {
          this.loading = false;

          if (!this.errorPopup) {
            this.successMsg = "Success! Racer updated!";
            this.successPopup = true;
          }
         
        }
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    resetFormSameClub() {
      this.newRacer.name = "";
      this.newRacer.gender = "";
      this.newRacer.division = "";
      this.newRacer.dob = "";
      this.finished = false;
      this.successPopup = false;
    },
    calcAgeAtDate() {
      var dob_parts = this.computedDateFormatted.split("/");
      var year = new Date().getFullYear();
      var today = new Date();
      var hint =
        today.getFullYear() -
        dob_parts[2] -
        ("0101" < dob_parts[1] + dob_parts[0]) +
        " as at 1/1/" +
        year;
      this.dobHint = hint;
      return hint;
    },
    calcAgeAtDateEdit() {
      var dob_parts = this.computedDateFormattedEdit.split("/");
      var year = new Date().getFullYear();
      var today = new Date();
      var hint =
        today.getFullYear() -
        dob_parts[2] -
        ("0101" < dob_parts[1] + dob_parts[0]) +
        " as at 1/1/" +
        year;
      this.dobHint = hint;
      return hint;
    },
    save(newRacerDOB) {
      this.$refs.menu.save(newRacerDOB);
      this.calcAgeAtDate();
    },
    saveEditDOB(editRacerdob) {
      this.$refs.editDOBMenu.save(editRacerdob);
      this.calcAgeAtDateEdit();
    },
    allocatedBox: function (value) {
      if (value == 0) {
        value = "No";
      } else if (value == 1) {
        value = "Yes";
      }
      return value;
    },
  
    async getClubData() {
      try {
        const response = await axios.get("https://dev.app1.rsc.org.nz/clubs");
        // JSON responses are automatically parsed.
        console.log(response);
        this.clubs = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    async getDivisionData() {
      try {
        const response = await axios.get(
          "https://dev.app1.rsc.org.nz/divisions"
        );
        // JSON responses are automatically parsed.
        console.log(response);
        this.divisions = response.data;
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
  },

  mounted() {
    
    this.getClubData();
    this.getDivisionData();
    this.getDivLabel();
  },
};
</script>

<style>
.vgt-wrap {
  max-width: 96%;
  min-width: 90%;
  justify-items: center;
  justify-items: center;
  justify-self: center;
  justify-content: centre;
}
.vgt-global-search__input {
  position: relative;
  padding-left: 40px;
  flex-grow: 0.95;
}
.racers {
  margin-top: 20px;
}
</style>
